<template>
  <div class="carrossel_container">
    <Flicking
      class="carrossel_box"
      :options="options"
      :plugins="plugins"
      ref="flicking"
    >
      <div v-for="(card, index) in listaPerfis" :key="index" class="slide-card">
        <div
          class="card"
          :style="{ backgroundImage: 'url(' + card.imgMini + ')' }"
          :id="card.codigo"
        >
          <span class="codigo" :style="{ color: card.cor }">
            {{ card.codigo }}
          </span>
          <span class="titulo">
            {{ card.titulo }}
          </span>
          <span class="persona" :style="{ color: card.cor }">
            {{ card.persona }}
          </span>
        </div>
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="card.codigo"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="viewport"
        >
          {{ card.descricao }}
        </b-tooltip>
      </div>
      <span slot="viewport" class="flicking-arrow-prev"> </span>
      <span slot="viewport" class="flicking-arrow-next"> </span>
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import { Pagination, Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/arrow.css";

export default {
  components: {
    Flicking: Flicking,
  },
  data() {
    return {
      options: {
        circular: true,
        align: "center",
        changeOnHold: true,
        circular: true,
        defaultIndex: this.firstCard,
      },
      plugins: [new Arrow({ moveByViewportSize: true })],
    };
  },

  props: {
    listaPerfis: Array,
    firstCard: Number,
    exibirTootipsConsultas: Boolean,
  },

  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.carrossel_container {
  width: 100%;
  height: 310px;
  padding: 0px 0 0 0;
  overflow: hidden;
  display: flex;
  margin-top: 10px;
}
.carrossel_box {
  width: 100%;
  margin: 0 5px;
}

.slide-card {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.card {
  border-radius: 20px;
  box-shadow: 5px 10px 10px rgba(23, 55, 127, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  place-items: flex-start;
  transition: 0.5s;
  height: 220px;
  width: 150px;
  margin: 20px 20px;
  border: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: center;

  &:hover {
    transform: scale(1.1);
  }

  .codigo {
    font-size: 32px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 21px;
    width: 100px;
  }
  .titulo {
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 38px;
    width: 140px;
    color: white;
  }
  .persona {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 54px;
    width: 110px;
  }
}

.flicking-arrow-prev,
.flicking-arrow-next {
  background-color: #bfbdbdb0;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 43%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  transform: translateY(-50%);
}

.flicking-arrow-prev {
  left: 10px;
}

.flicking-arrow-next {
  right: 10px;
}

.flicking-arrow-prev::before,
.flicking-arrow-prev::after,
.flicking-arrow-next::before,
.flicking-arrow-next::after {
  background-color: white;
  width: 15px;
  height: 4px;
  position: absolute;
  content: "";
}

.flicking-arrow-prev::before {
  transform: rotate(-45deg);
  transform-origin: 0% 50%;
  left: 11px;
  top: 48%;
}

.flicking-arrow-prev::after {
  left: 11px;
  top: calc(52% - 4px);
  transform: rotate(45deg);
  transform-origin: 0% 50%;
}

.flicking-arrow-next::before {
  left: 10px;
  top: 48%;
  transform: rotate(45deg);
  transform-origin: 100% 50%;
}

.flicking-arrow-next::after {
  left: 10px;
  top: calc(52% - 4px);
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
}
</style>

<style lang="scss"></style>
